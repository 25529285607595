"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/298yoga-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.OrderTypeTrans = exports.OrderType = exports.OrderStatusTransTagColor = exports.OrderStatusTrans = exports.OrderStatus = void 0;
var _defineProperty2 = _interopRequireDefault(require("C:/Users/Admin/Documents/298yoga-admin/node_modules/@babel/runtime/helpers/defineProperty.js"));
var OrderStatus;
(function (OrderStatus) {
  OrderStatus["Pending"] = "PENDING";
  OrderStatus["Complete"] = "COMPLETE";
  OrderStatus["Fail"] = "FAIL";
})(OrderStatus || (exports.OrderStatus = OrderStatus = {}));
var OrderStatusTrans = exports.OrderStatusTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, OrderStatus.Complete, "Hoàn tất"), OrderStatus.Pending, "Đang xử lý"), OrderStatus.Fail, "Thất bại");
var OrderStatusTransTagColor = exports.OrderStatusTransTagColor = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, OrderStatus.Pending, ""), OrderStatus.Complete, "success"), OrderStatus.Fail, "danger");
var OrderType;
(function (OrderType) {
  OrderType["Single"] = "SINGLE";
  OrderType["Pack"] = "PACK";
})(OrderType || (exports.OrderType = OrderType = {}));
var OrderTypeTrans = exports.OrderTypeTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)({}, OrderType.Single, "Mua bài tập"), OrderType.Pack, "Mua gói tập");