"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es.function.name.js");
var render = exports.render = function render() {
  var _vm$formData$staff;
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-dialog", {
    attrs: {
      "close-on-click-modal": false,
      title: _vm.dialogStatus == "update" ? "Chi tiết đơn hàng" : "Thêm đơn hàng",
      visible: _vm.dialogVisible,
      width: "700px",
      top: "15px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.dialogLoading,
      expression: "dialogLoading"
    }]
  }, [[_c("div", [_c("h3", [_c("strong", [_vm._v("Người mua: ")]), _vm._v(_vm._s(((_vm$formData$staff = _vm.formData.staff) === null || _vm$formData$staff === void 0 ? void 0 : _vm$formData$staff.name) || ""))]), _vm.formData.type === _vm.OrderType.Single && _vm.formData.book ? _c("div", {
    staticClass: "item"
  }, [_c("h3", [_vm._v(_vm._s(_vm.formData.book.name))]), _c("p", [_c("strong", [_vm._v("Số lượng:")]), _vm._v(" 1")]), _c("p", [_c("strong", [_vm._v("Giá:")]), _vm._v(" " + _vm._s(_vm.formatCurrency(_vm.formData.amount)))]), _c("p", [_c("strong", [_vm._v("Ngày mua:")]), _vm._v(" " + _vm._s(_vm.$formatDateTime(_vm.formData.createdAt)) + " ")]), _c("p", [_c("strong", [_vm._v("Apple Id:")]), _vm._v(" " + _vm._s(_vm.formData.book.appleId) + " ")]), _c("p", [_c("strong", [_vm._v("Google Id:")]), _vm._v(" " + _vm._s(_vm.formData.book.googleId) + " ")])]) : _vm._e(), _vm.formData.type === _vm.OrderType.Pack && _vm.formData.packDetail ? _c("div", [_c("p", [_c("strong", [_vm._v("Tên gói:")]), _vm._v(" " + _vm._s(_vm.formData.packDetail.productPack.name) + " ")]), _c("p", [_c("strong", [_vm._v("Tên lựa chọn gói:")]), _vm._v(" " + _vm._s(_vm.formData.packDetail.name) + " ")]), _c("p", [_c("strong", [_vm._v("Giá lựa chọn gói:")]), _vm._v(" " + _vm._s(_vm.$formatNumberVN(_vm.formData.packDetail.price)) + " ")]), _c("p", [_c("strong", [_vm._v("Số ngày hết hạn:")]), _vm._v(" " + _vm._s(_vm.formData.packDetail.expiresDay) + " ngày (Bắt đầu từ " + _vm._s(_vm.$formatDateTime(_vm.formData.createdAt)) + ") ")]), _c("p", [_c("strong", [_vm._v("Apple Id:")]), _vm._v(" " + _vm._s(_vm.formData.packDetail.appleId) + " ")]), _c("p", [_c("strong", [_vm._v("Google Id:")]), _vm._v(" " + _vm._s(_vm.formData.packDetail.googleId) + " ")]), _c("p", [_c("strong", [_vm._v("Phương thức thanh toán:")]), _vm._v(" " + _vm._s(_vm.PaymentTypeTrans[_vm.formData.paymentType]) + " ")])]) : _vm._e()])]], 2)]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;